<template>
  <v-scale-transition>
    <kits-panel :kits-items="subscriberGroup">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Incentives
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams } from '@/rest'
export default {
  components: {
    KitsPanel: () =>
      import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      isFc: false,
      isSLD: false,
      isMySiswaSpecial: false,
    }
  },
  computed: {
    subscriberGroup: function () {
      const menu = [
        {
          icon: 'mdi-cash-outline',
          path: '/income',
          title: 'Income',
          desc: 'Income statement.',
        },
      ]
      this.isFc === false && this.isSLD === false && menu.push(
        {
          icon: 'mdi-baby-bottle-outline',
          path: '/incentive_ssp',
          title: 'Special Season Pass',
          desc: 'Welcome deals incentive.',
        },
        {
          icon: 'mdi-gift-open-outline',
          path: '/incentive_wawa',
          title: 'WAWA',
          desc: 'Special incentive program.',
        },
        {
          icon: 'mdi-gift-open-outline',
          path: '/incentive_perpaduan',
          title: 'Perpaduan',
          desc: 'Special incentive program.',
        },
        {
          icon: 'mdi-account-group',
          path: '/recruitment_incentive',
          title: 'Dealer Appointment',
          desc: 'Dealer appointment incentive.',
        },
        {
          icon: 'mdi-account-group',
          path: '/recruitment_incentive',
          title: 'Dealer Appointment',
          desc: 'Dealer appointment incentive.',
        },
        {
          icon: 'card_giftcard',
          path: '/black_ai',
          title: 'Black AI',
          desc: 'Black activation incentive.',
        },
        {
          icon: 'card_giftcard',
          path: '/prepaid_ai',
          title: 'Prepaid AI',
          desc: 'Prepaid activation incentive.',
        },
        {
          icon: 'card_giftcard',
          path: '/black_payment_ai',
          title: 'Black Payment',
          desc: 'Black Payment activation incentive.',
        },
        {
          icon: 'mdi-target',
          path: '/target_kpi',
          title: 'Target KPI',
          desc: 'Annual Payout & Black AI',
        },
      )
      this.isFc === false && this.isSLD === false &&
        menu.push(
          {
            icon: 'mdi-reload',
            path: '/royalty_incentives',
            title: 'RI',
            desc: 'Royalty incentives.',
          },
        )
      this.isFc === true &&
        menu.push({
          icon: 'mdi-soccer',
          path: '/fc_incentives',
          title: 'Fc Incentive',
          desc: 'Special Incentive Record.',
        })

      this.isMySiswaSpecial === true &&
        menu.push({
          icon: 'mdi-account-school',
          path: '/mysiswa_special_incentives',
          title: 'MySiswa Special Incentive',
          desc: 'Special Incentive Record.',
        })

      this.isSLD === true &&
        menu.push(
          {
            icon: 'mdi-reload',
            path: '/royalty_incentives_sld',
            title: 'RI',
            desc: 'Royalty incentives.',
          },
        )

      return menu
    },
  },
  mounted: function () {
    this.$rest
      .get('getDealerResources.php', createGetParams({}))
      .then(
        function (response) {
          this.isFc = response.data.isFc
          this.isSLD = response.data.isSLD
          this.isMySiswaSpecial = response.data.isMySiswaSpecial
        }.bind(this),
      )
      .catch((error) => {
        this.ecash = '<span style="color:red">' + error.message + '</span>'
      })
  },
}
</script>
